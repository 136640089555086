.ltv_program {
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.ltv_caption_container {
  transition: opacity 500ms, transform 300ms ease-in-out;
}

.ltv_caption {
  text-align: center;
  text-transform: uppercase;
  font-size: 3.02083vw;
  font-weight: 600;
}

.ltv_caption_up {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.82292vw;
  margin-bottom: -0.83333vw;
}

.ltv_caption_down {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.82292vw;
  margin-top: -0.67708vw;
}

.ltv_userbox_container {
  display: flex;
  padding: 2.5vw 2vw;
}

.ltv_userbox_container.two .ltv_userbox {
  width: calc(50% - 2vw);
  margin: 0 1vw;
}

.ltv_userbox_container.three .ltv_userbox {
  width: calc(33.33% - 2vw);
  margin: 0 1vw;
}

.ltv_userbox_container.md  {
  flex-wrap: wrap;
  flex-basis: 25%;
}

.ltv_userbox_container.md {
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.ltv_userbox_container.md .ltv_userbox {
  width: calc(25% - 2vw);
  margin: 1vw;
}

.ltv_userbox_container.sm  {
  flex-basis: 4%;
  flex-wrap: wrap;
  justify-content: center;
}

.ltv_userbox_container.sm  {
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.ltv_userbox_container.sm .ltv_userbox {
  margin: 0.5vw;
}

.ltv_userbox_container.sm .ltv_userbox .ltv_avatar {
  height: 5.20833vw;
  width: 5.20833vw;
}

.ltv_userbox {
  display: flex;
  border-radius: 2.60417vw;
  overflow: hidden;
  position: relative;
}

.ltv_userbox .ltv_avatar, .ltv_userbox .ltv_name, .ltv_userbox .ltv_st {
  position: relative;
  z-index: 1;
}

.ltv_userbox .ltv_avatar {
  width: 5.20833vw;
  border-radius: 2.60417vw;
  overflow: hidden;
  background: #32456d;
  flex-shrink: 0;
}

.ltv_userbox .ltv_avatar img {
  width: 100%;
  height: 100%;
  min-height: 5.20833vw;
  /* height: auto; */
}

.ltv_userbox .ltv_name {
  font-size: 1.82292vw;
  color: white;
  display: flex;
  flex-grow: 1;
  padding-left: 1.5vw;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ltv_userbox .ltv_name .ltv_wrapper {
  margin-top: auto;
  margin-bottom: auto;
}

.ltv_userbox .ltv_st {
  display: flex;
  width: 11vw;
}

.ltv_userbox .ltv_st > .ltv_wrapper {
  height: 2.60417vw;
  margin: auto;
  position: relative;
  border-radius: 1.30208vw;
  color: white;
  text-align: center;
  width: 9vw;
  font-size: 1.04167vw;
  font-weight: 600;
  line-height: 2.8vw;
}

.ltv_userbox .ltv_st > .ltv_wrapper .ltv_wrapper {
  text-shadow: 0vw 0vw 0.52083vw #a727df;
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0vw;
  bottom: 0vw;
  z-index: 2;
  background-color: #131f3a;
  border-radius: 1.30208vw;
  box-shadow: inset 0vw 0vw 0.78125vw rgba(79, 115, 196, 0.5);
}

.ltv_userbox .ltv_st > .ltv_wrapper:before {
  content: '';
  position: absolute;
  top: -0.05208vw;
  right: -0.05208vw;
  left: -0.05208vw;
  bottom: -0.05208vw;
  z-index: 1;
  border-radius: 1.35417vw;
  background: linear-gradient(to bottom, #3c4f7b, #131f3a);
  box-shadow: 0vw 0vw 0.52083vw #15171a;
}

.ltv_userbox.ltv_orange {
  background-color: #f09230;
}

.ltv_userbox.ltv_orange .ltv_st > .ltv_wrapper .ltv_wrapper {
  text-shadow: 0vw 0vw 0.52083vw #a727df;
  background-color: #131f3a;
  box-shadow: inset 0vw 0vw 0.78125vw #4f73c4;
}

.ltv_userbox.ltv_orange .ltv_st > .ltv_wrapper:before {
  background: linear-gradient(to bottom, #ffd3a5, #cd6c07);
  box-shadow: 0vw 0vw 0.52083vw #ffbc76;
}

.ltv_userbox.ltv_orange:before, .ltv_userbox.ltv_orange:after {
  content: '';
  background-color: white;
  width: 1.25vw;
  height: 5.20833vw;
  position: absolute;
  top: 0%;
  transform: skewX(45deg);
  opacity: 0.2;
  pointer-events: none;
}

.ltv_userbox.ltv_orange:before {
  right: 2.60417vw;
}

.ltv_userbox.ltv_orange:after {
  right: 0vw;
}

.ltv_userbox.ltv_dark {
  background-color: #0b1221;
}

.ltv_userbox.ltv_dark .ltv_st > .ltv_wrapper .ltv_wrapper {
  text-shadow: 0vw 0vw 0.52083vw #a727df;
  background-color: #0b1221;
  box-shadow: inset 0vw 0vw 0.78125vw transparent;
}

.ltv_userbox.ltv_dark .ltv_st > .ltv_wrapper:before {
  top: -0.10417vw;
  right: -0.10417vw;
  left: -0.10417vw;
  bottom: -0.10417vw;
  background: linear-gradient(to bottom, #3c4f7b, #14203b);
  box-shadow: 0vw 0vw 0.52083vw transparent;
}

.ltv_userbox.ltv_dark:before, .ltv_userbox.ltv_dark:after {
  content: '';
  background-color: #292f3c;
  width: 1.25vw;
  height: 5.20833vw;
  position: absolute;
  top: 0%;
  transform: skewX(45deg);
  opacity: 0.2;
  pointer-events: none;
}

.ltv_userbox.ltv_dark:before {
  right: 2.60417vw;
}

.ltv_userbox.ltv_dark:after {
  right: 0vw;
}

.ltv_userstate {
  width: 0.83333vw;
  position: relative;
}

.ltv_userstate.ltv_stay .ltv_statecircle {
  width: 0.72917vw;
  height: 0.72917vw;
  background-color: white;
  border-radius: 0.36458vw;
  position: absolute;
  left: 50%;
  margin-left: -0.36458vw;
  top: 50%;
  margin-top: -0.36458vw;
}

.ltv_userstate.ltv_up .ltv_statecircle {
  width: 0.3125vw;
  height: 0.3125vw;
  background-color: white;
  border-radius: 0.15625vw;
  position: absolute;
  left: 50%;
  margin-left: -0.15625vw;
  top: 50%;
  margin-top: -0.3125vw;
}

.ltv_userstate.ltv_up .ltv_statecircle:before {
  content: '';
  width: 0vw;
  height: 0vw;
  position: absolute;
  top: -1.04167vw;
  left: -0.26042vw;
  border: 0.41667vw solid transparent;
  border-bottom: 0.41667vw solid white;
}

.ltv_userstate.ltv_up .ltv_statecircle:after {
  content: '';
  width: 0.3125vw;
  height: 0.3125vw;
  background-color: white;
  border-radius: 0.15625vw;
  position: absolute;
  bottom: -0.52083vw;
}

.ltv_userstate.ltv_down .ltv_statecircle {
  width: 0.3125vw;
  height: 0.3125vw;
  background-color: white;
  border-radius: 0.15625vw;
  position: absolute;
  left: 50%;
  margin-left: -0.15625vw;
  top: 50%;
  margin-top: -0.3125vw;
}

.ltv_userstate.ltv_down .ltv_statecircle:before {
  content: '';
  width: 0vw;
  height: 0vw;
  position: absolute;
  bottom: -1.09375vw;
  left: -0.26042vw;
  border: 0.41667vw solid transparent;
  border-top: 0.41667vw solid white;
}

.ltv_userstate.ltv_down .ltv_statecircle:after {
  content: '';
  width: 0.3125vw;
  height: 0.3125vw;
  background-color: white;
  border-radius: 0.15625vw;
  position: absolute;
  top: -0.52083vw;
}

.ltv_usernumber {
  width: 5.20833vw;
  display: flex;
}

.ltv_usernumber > .ltv_wrapper {
  margin: auto;
  position: relative;
  border-radius: 1.97917vw;
  color: white;
  text-align: center;
  width: 3.95833vw;
  height: 3.95833vw;
  font-size: 1.82292vw;
  font-weight: 800;
  line-height: 4.1vw;
  text-indent: -1px;
}

.ltv_usernumber > .ltv_wrapper svg {
  position: absolute;
  top: -0.78125vw;
  left: 0.26042vw;
  width: 3.4375vw;
  pointer-events: none;
  display: none;
}

.ltv_usernumber > .ltv_wrapper svg path {
  fill: #f09230;
}

.ltv_usernumber > .ltv_wrapper .ltv_wrapper {
  width: 3.95833vw;
  height: 3.95833vw;
  border-radius: 1.97917vw;
  position: relative;
  z-index: 2;
  background-color: #131f3a;
  box-shadow: inset 0vw 0vw 0.78125vw rgba(79, 115, 196, 0.5);
}

.ltv_usernumber > .ltv_wrapper:before {
  content: '';
  position: absolute;
  top: -0.05208vw;
  right: -0.05208vw;
  left: -0.05208vw;
  bottom: -0.05208vw;
  background-color: red;
  z-index: 1;
  border-radius: 2.03125vw;
  background: linear-gradient(to bottom, #3c4f7b, #131f3a);
  box-shadow: 0vw 0vw 0.52083vw #15171a;
}

.ltv_usernumber.ltv_crowngold > .ltv_wrapper svg {
  display: block;
}

.ltv_usernumber.ltv_crowngold > .ltv_wrapper svg path {
  fill: #ffa200;
}

.ltv_usernumber.ltv_crowngold > .ltv_wrapper .ltv_wrapper {
  background-color: #ee9130;
  box-shadow: inset 0vw 0vw 0.78125vw #ffe083;
}

.ltv_usernumber.ltv_crowngold > .ltv_wrapper:before {
  background: linear-gradient(to bottom, #fcbf7f, #ee9130);
  box-shadow: 0vw 0vw 0.26042vw #ec850c;
}

.ltv_usernumber.ltv_crownsilver > .ltv_wrapper svg {
  display: block;
}

.ltv_usernumber.ltv_crownsilver > .ltv_wrapper svg path {
  fill: #bfbfbf;
}

.ltv_usernumber.ltv_crownsilver > .ltv_wrapper .ltv_wrapper {
  background-color: #bfbfbf;
  box-shadow: inset 0vw 0vw 0.78125vw #e7e7e7;
}

.ltv_usernumber.ltv_crownsilver > .ltv_wrapper:before {
  background: linear-gradient(to bottom, #e3e3e3, #bfbfbf);
  box-shadow: 0vw 0vw 0.26042vw #bfbfbf;
}

.ltv_usernumber.ltv_crownbronze > .ltv_wrapper svg {
  display: block;
}

.ltv_usernumber.ltv_crownbronze > .ltv_wrapper svg path {
  fill: #bb6526;
}

.ltv_usernumber.ltv_crownbronze > .ltv_wrapper .ltv_wrapper {
  background-color: #bb6526;
  box-shadow: inset 0vw 0vw 0.78125vw #e6af86;
}

.ltv_usernumber.ltv_crownbronze > .ltv_wrapper:before {
  background: linear-gradient(to bottom, #ef9655, #93542b);
  box-shadow: 0vw 0vw 0.26042vw #bb6526;
}

.ltv_twocolumns {
  display: flex;
  transition: opacity 500ms, transform 300ms ease-in-out;
}

.ltv_leaderboard.entered > .ltv_twocolumns {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_leaderboard.entering > .ltv_twocolumns,
.ltv_leaderboard.exiting > .ltv_twocolumns,
.ltv_leaderboard.exited > .ltv_twocolumns {
  transform: translateY(100%);
  opacity: 0;
  will-change: transform;
}

.ltv_twocolumns .ltv_twocolumns_column {
  flex-basis: 50%;
  padding: 1.5625vw;
  transition: opacity 500ms, transform 300ms ease-in-out;
}

.ltv_leaderboard > .ltv_twocolumns .ltv_twocolumns_column.entered {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_leaderboard > .ltv_twocolumns .ltv_twocolumns_column.entering,
.ltv_leaderboard > .ltv_twocolumns .ltv_twocolumns_column.exiting,
.ltv_leaderboard > .ltv_twocolumns .ltv_twocolumns_column.exited {
  transform: translateY(100%);
  opacity: 0;
  will-change: transform;
}

.ltv_twocolumns .ltv_right.transition-container {
  display: flex;
}

.ltv_twocolumns .ltv_left .ltv_userboxrow, .ltv_twocolumns .ltv_right .ltv_userboxrow {
  height: 5.20833vw;
  display: flex;
}

.ltv_twocolumns .ltv_left .ltv_userboxrow:not(:last-child), .ltv_twocolumns .ltv_right .ltv_userboxrow:not(:last-child) {
  margin-bottom: 1.38vw;
}

.ltv_twocolumns .ltv_left .ltv_userboxrow .ltv_userstate, .ltv_twocolumns .ltv_right .ltv_userboxrow .ltv_userstate {
  margin-right: 0.3125vw;
}

.ltv_twocolumns .ltv_left .ltv_userboxrow .ltv_usernumber, .ltv_twocolumns .ltv_right .ltv_userboxrow .ltv_usernumber {
  margin-right: 0.3125vw;
}

.ltv_twocolumns .ltv_left .ltv_userboxrow .ltv_userbox, .ltv_twocolumns .ltv_right .ltv_userboxrow .ltv_userbox {
  flex-grow: 1;
}

.ltv_leaderboard {
}

.ltv_leaderboard > .ltv_caption_container {
  padding-top: 2.08333vw;
}

.ltv_leaderboard.entered > .ltv_caption_container {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_leaderboard.entering > .ltv_caption_container,
.ltv_leaderboard.exiting > .ltv_caption_container,
.ltv_leaderboard.exited > .ltv_caption_container {
  transform: translateY(-100%);
  opacity: 0;
  will-change: transform;
}

.ltv_personfocus {
  width: 50%;
  margin: 0 auto;
  padding-top: 5vw;
  transition: opacity 500ms, transform 300ms ease-in-out;
}

.ltv_personfocus.plugin-program {
  padding-top: 0;
  margin: 0;
  width: 100%;
}

.ltv_personfocus.entered {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_personfocus.entering,
.ltv_personfocus.exiting,
.ltv_personfocus.exited {
  transform: translateY(100%);
  opacity: 0;
  will-change: transform;
}

.ltv_userdetailed {
  background-color: #192b53;
  border-radius: 2.34375vw;
  box-shadow: 0vw 0vw 3px 3px #0000000d;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ltv_userdetailed .ltv_up {
  display: flex;
  padding: 1.5625vw;
}

.ltv_userdetailed .ltv_up .ltv_avatar {
  overflow: hidden;
  width: 16.97917vw;
  height: 16.97917vw;
  border-radius: 50%;
  background: #32456d;
  flex-shrink: 0;
}

.ltv_userdetailed .ltv_up .ltv_avatar img {
  max-width: 100%;
  height: auto;
}

.ltv_userdetailed .ltv_up .ltv_name {
  display: flex;
  flex: 1;
  padding-left: 2.60417vw;
}

.ltv_userdetailed .ltv_up .ltv_name .ltv_wrapper {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 2.60417vw;
  font-weight: 600;
  line-height: 3.125vw;
}

.ltv_userdetailed .ltv_down {
  display: flex;
  flex-grow: 1;
  /* justify-content: space-between; */
  width: 100%;
}

.ltv_userdetailed .ltv_down > * {
  flex-grow: 1;
}

.ltv_userdetailed .ltv_down .ltv_left, .ltv_userdetailed .ltv_down .ltv_center, .ltv_userdetailed .ltv_down .ltv_right {
  flex-basis: 33%;
  padding: 1.82292vw 0.52083vw 2.60417vw 0.52083vw;
}

.ltv_userdetailed .ltv_down .ltv_left .ltv_colcaption, .ltv_userdetailed .ltv_down .ltv_center .ltv_colcaption, .ltv_userdetailed .ltv_down .ltv_right .ltv_colcaption {
  text-align: center;
  font-size: 1.5625vw;
  padding-bottom: 2.08333vw;
  line-height: 1.875vw;
}

.ltv_userdetailed .ltv_down .ltv_left .ltv_summ, .ltv_userdetailed .ltv_down .ltv_center .ltv_summ, .ltv_userdetailed .ltv_down .ltv_right .ltv_summ {
  width: 9.375vw;
  padding-bottom: 2.60417vw;
  margin: auto;
}

.ltv_userdetailed .ltv_down .ltv_left .ltv_summ > .ltv_wrapper, .ltv_userdetailed .ltv_down .ltv_center .ltv_summ > .ltv_wrapper, .ltv_userdetailed .ltv_down .ltv_right .ltv_summ > .ltv_wrapper {
  height: 2.60417vw;
  margin: auto;
  position: relative;
  border-radius: 1.30208vw;
  color: white;
  text-align: center;
  font-size: 1.04167vw;
  font-weight: 600;
  line-height: 2.5vw;
}

.ltv_userdetailed .ltv_down .ltv_left .ltv_summ > .ltv_wrapper .ltv_wrapper, .ltv_userdetailed .ltv_down .ltv_center .ltv_summ > .ltv_wrapper .ltv_wrapper, .ltv_userdetailed .ltv_down .ltv_right .ltv_summ > .ltv_wrapper .ltv_wrapper {
  text-shadow: 0vw 0vw 0.52083vw #a727df;
  position: absolute;
  top: 0vw;
  left: 0vw;
  right: 0vw;
  bottom: 0vw;
  z-index: 2;
  border-radius: 1.30208vw;
  text-shadow: 0vw 0vw 0.52083vw #a727df;
  background-color: #0b1221;
}

.ltv_userdetailed .ltv_down .ltv_left .ltv_summ > .ltv_wrapper:before, .ltv_userdetailed .ltv_down .ltv_center .ltv_summ > .ltv_wrapper:before, .ltv_userdetailed .ltv_down .ltv_right .ltv_summ > .ltv_wrapper:before {
  content: '';
  position: absolute;
  z-index: 1;
  border-radius: 1.35417vw;
  top: -0.10417vw;
  right: -0.10417vw;
  left: -0.10417vw;
  bottom: -0.10417vw;
  background: linear-gradient(to bottom, #3c4f7b, #14203b);
}

.ltv_userdetailed .ltv_down .ltv_left .ltv_usernumber, .ltv_userdetailed .ltv_down .ltv_center .ltv_usernumber, .ltv_userdetailed .ltv_down .ltv_right .ltv_usernumber {
  margin: auto;
}

.ltv_userdetailed .ltv_down .ltv_left {
  background: linear-gradient(to bottom, #142240, #162850);
  border-bottom-left-radius: 2.34375vw;
  box-shadow: inset -3px 3px 3px #0000002e;
}

.ltv_userdetailed .ltv_down .ltv_center {
  background: linear-gradient(to bottom, #142240, #162850);
  box-shadow: inset 0 3px 3px #0000002e;
}

.ltv_userdetailed .ltv_down .ltv_right {
  background: linear-gradient(to bottom, #142240, #162850);
  border-bottom-right-radius: 2.34375vw;
  box-shadow: inset 0vw 0vw 10px #00000052;
  box-shadow: inset 3px 3px 3px #0000002e;
}

.ltv_logoboard {
  height: 100vh;
  position: relative;
}

.ltv_logoboard .ltv_logo {
  transition: opacity 500ms, transform 300ms ease-in-out;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60%;
  top: 0;
  left: 0;
  right: 0;
}

.ltv_logoboard.entered .ltv_logo {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_logoboard.entering .ltv_logo,
.ltv_logoboard.exiting .ltv_logo,
.ltv_logoboard.exited .ltv_logo {
  transform: translateY(-100%);
  opacity: 0;
  will-change: transform;
}

.ltv_logoboard .ltv_logo img {
  width: 46.04167vw;
  height: 10.83333vw;
}

.ltv_logoboard .ltv_clock {
  transition: opacity 500ms, transform 300ms ease-in-out;
  position: absolute;
  height: 40%;
  bottom: 0;
  right: 0;
  left: 0;
}

.ltv_logoboard.entered .ltv_clock {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_logoboard.entering .ltv_clock,
.ltv_logoboard.exiting .ltv_clock,
.ltv_logoboard.exited .ltv_clock {
  transform: translateY(100%);
  opacity: 0;
  will-change: transform;
}

.ltv_logoboard .ltv_time {
  font-size: 5.20833vw;
  position: absolute;
  bottom: 6.25vw;
  left: 0vw;
  right: 0vw;
  text-align: center;
}

.ltv_logoboard .ltv_date {
  font-size: 1.82292vw;
  font-weight: 900;
  position: absolute;
  bottom: 2.86458vw;
  left: 0vw;
  right: 0vw;
  text-align: center;
}

.ltv_qualificationboard {
  padding-top: 1.30208vw;
}

.ltv_qualificationboard .ltv_up, .ltv_qualificationboard .ltv_down {
  transition: opacity 500ms, transform 300ms ease-in-out;
}

.ltv_qualificationboard.entered .ltv_up {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_qualificationboard.entering .ltv_up,
.ltv_qualificationboard.exiting .ltv_up,
.ltv_qualificationboard.exited .ltv_up {
  transform: translateY(-100%);
  opacity: 0;
  will-change: transform;
}

.ltv_qualificationboard.entered .ltv_down {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_qualificationboard.entering .ltv_down,
.ltv_qualificationboard.exiting .ltv_down,
.ltv_qualificationboard.exited .ltv_down {
  transform: translateY(100%);
  opacity: 0;
  will-change: transform;
}

.ltv_qualificationboard .ltv_userbox_lg {
  border-radius: 4.58333vw;
  max-width: 67.70833vw;
  margin: 0 auto;
}

.ltv_qualificationboard .ltv_userbox_lg .ltv_avatar {
  width: 9.16667vw;
  border-radius: 4.58333vw;
}

.ltv_qualificationboard .ltv_userbox_lg .ltv_name {
  font-size: 3.125vw;
  padding-left: 3.38542vw;
}

.ltv_qualificationboard .ltv_userbox_lg .ltv_st {
  width: 19.79167vw;
}

.ltv_qualificationboard .ltv_userbox_lg .ltv_st > .ltv_wrapper {
  height: 4.6875vw;
  border-radius: 2.34375vw;
  width: 14.84375vw;
  font-size: 1.82292vw;
  line-height: 4.47917vw;
}

.ltv_qualificationboard .ltv_userbox_lg .ltv_st > .ltv_wrapper .ltv_wrapper {
  border-radius: 2.34375vw;
}

.ltv_qualificationboard .ltv_userbox_lg .ltv_st > .ltv_wrapper:before {
  border-radius: 2.39583vw;
}

.ltv_qualificationboard .ltv_userbox_lg:before, .ltv_qualificationboard .ltv_userbox_lg:after {
  width: 2.34375vw;
  height: 9.16667vw;
}

.ltv_qualificationboard .ltv_userbox_lg:before {
  right: 4.6875vw;
}

.ltv_qualificationboard .ltv_userbox_lg:after {
  right: 0vw;
}

.ltv_qualificationboard .ltv_userbox_sm {
  display: flex;
}

.ltv_qualificationboard .ltv_userbox_sm  .ltv_userbox {
  border-radius: 3.75vw;
  display: block;
}

.ltv_qualificationboard .ltv_userbox_sm  .ltv_userbox .ltv_avatar {
  width: 7.5vw;
  border-radius: 3.75vw;
  float: left;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox .ltv_name {
  font-size: 1.82292vw;
  padding-left: 0.78125vw;
  margin-top: 0.78125vw;
  margin-bottom: 0.3125vw;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox .ltv_st {
  width: auto;
  height: 2.60417vw;
  display: block;
  padding-left: 0.78125vw;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox .ltv_st > .ltv_wrapper {
  height: 2.60417vw;
  border-radius: 1.30208vw;
  width: 14.84375vw;
  font-size: 1.30208vw;
  line-height: 2.60417vw;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox .ltv_st > .ltv_wrapper .ltv_wrapper {
  border-radius: 1.30208vw;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox .ltv_st > .ltv_wrapper:before {
  border-radius: 1.35417vw;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox:before, .ltv_qualificationboard .ltv_userbox_sm .ltv_userbox:after {
  width: 1.82292vw;
  height: 7.5vw;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox:before {
  right: 3.90625vw;
}

.ltv_qualificationboard .ltv_userbox_sm .ltv_userbox:after {
  right: 0vw;
}

.ltv_qualificationboard .ltv_avatars {
  padding: 1.30208vw 2.08333vw 1.30208vw 2.08333vw;
  text-align: center;
}

.ltv_qualificationboard .ltv_avatars .ltv_avatar {
  width: 5.52083vw;
  height: 5.52083vw;
  border-radius: 2.70833vw;
  overflow: hidden;
  margin: 0.26042vw;
  display: inline-block;
}

.ltv_qualificationboard .ltv_avatars .ltv_avatar img {
  max-width: 100%;
  height: auto;
}

.ltv_qualificationboard .ltv_down {
  max-width: 40.625vw;
  margin-left: auto;
  margin-right: auto;
}

.ltv_qualificationboard .ltv_down .ltv_text {
  font-size: 1.66667vw;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.04167vw;
}

.ltv_qualificationboard .ltv_userslist .ltv_userbox {
  margin-bottom: 1.04167vw;
}

.ltv_qualificationboard .ltv_greybox {
  height: 8.54167vw;
  background-color: white;
  position: relative;
  border-radius: 4.27083vw;
  overflow: hidden;
  margin-bottom: 2.86458vw;
}

.ltv_qualificationboard .ltv_greybox:before, .ltv_qualificationboard .ltv_greybox:after {
  content: '';
  background-color: #f1f1f3;
  width: 1.35417vw;
  height: 8.54167vw;
  position: absolute;
  top: 0%;
  transform: skewX(45deg);
  pointer-events: none;
}

.ltv_qualificationboard .ltv_greybox:before {
  right: 2.86458vw;
}

.ltv_qualificationboard .ltv_greybox:after {
  right: 0.41667vw;
}

.ltv_qualificationboard .ltv_greybox .wrapper {
  padding: 0.52083vw;
  position: relative;
}

.ltv_qualificationboard .ltv_greybox .wrapper:before, .ltv_qualificationboard .ltv_greybox .wrapper:after {
  content: '';
  background-color: #f1f1f3;
  width: 1.35417vw;
  height: 8.54167vw;
  position: absolute;
  top: 0%;
  transform: skewX(45deg);
  pointer-events: none;
}

.ltv_qualificationboard .ltv_greybox .wrapper:before {
  left: 2.86458vw;
}

.ltv_qualificationboard .ltv_greybox .wrapper:after {
  left: 0.41667vw;
}

.ltv_qualificationboard .ltv_greybox .wrapper .greybox_border {
  z-index: 2;
  position: relative;
  border: 0.46875vw solid #131f3a;
  color: #131f3a;
  font-size: 4.6875vw;
  font-weight: 600;
  line-height: 7vw;
  text-align: center;
  border-radius: 3.75vw;
  height: 7.5vw;
}

.ltv_sumboard {
  display: flex;
  flex-direction: column;
}

.ltv_sumboard .ltv_sumboardcaption {
  padding-top: 7.8125vw;
  text-transform: uppercase;
  text-align: center;
  font-size: 3.75vw;
  font-weight: 600;
  line-height: 190%;
  margin-bottom: 8.33333vw;
  transition: opacity 500ms, transform 300ms ease-in-out;
}

.ltv_sumboard.entered > .ltv_sumboardcaption {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_sumboard.entering > .ltv_sumboardcaption,
.ltv_sumboard.exiting > .ltv_sumboardcaption,
.ltv_sumboard.exited > .ltv_sumboardcaption {
  transform: translateY(-100%);
  opacity: 0;
  will-change: transform;
}

.ltv_sumboard .ltv_sumboardcaption_sub {
  text-transform: uppercase;
  text-align: center;
  font-size: 3.75vw;
  line-height: 190%;
  margin-bottom: 1vw;
  font-weight: 200;
}

.ltv_sumboard .ltv_sum {
  transition: opacity 500ms, transform 300ms ease-in-out;
}

.ltv_sumboard.entered > .ltv_sum {
  transform: translateY(0);
  opacity: 1;
  will-change: transform;
}
.ltv_sumboard.entering > .ltv_sum,
.ltv_sumboard.exiting > .ltv_sum,
.ltv_sumboard.exited > .ltv_sum {
  transform: translateY(calc(100% + 16vw));
  opacity: 0;
  will-change: transform;
}

.ltv_sumboard .ltv_orangebox {
  margin: auto;
  height: 13.33333vw;
  width: 41.66667vw;
  border: 0.52083vw solid #f09230;
  position: relative;
  border-radius: 6.66667vw;
  padding: 0.52083vw;
}

.ltv_sumboard .ltv_orangebox .ltv_wrapper {
  z-index: 2;
  position: relative;
  text-shadow: 0vw 0vw 0.26042vw #502c06;
  background-color: #f09230;
  color: #fff;
  font-size: 4.79167vw;
  font-weight: 600;
  line-height: 11.5vw;
  text-align: center;
  border-radius: 5.625vw;
  height: 11.25vw;
}

