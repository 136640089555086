.btn-icon.rounded-circle {
  font-size: 1.7rem;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
}

.btn-file input[type="file"]:hover {
  cursor: pointer;
}
