.viewport-logout {
  position: absolute;
  right: 20px;
  top: 20px;
}

.viewport-logout button {
  visibility: hidden;
}

.viewport-logout:hover button {
  visibility: visible;
}
