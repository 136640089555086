.viewport-password-dialog {
  position: absolute;
  height: 100%;
  width: 100%;
}

.viewport-password-dialog > .inner {
  width: 20vw;
  height: 10vh;
  margin: 45vh auto;
  color: #fff;
  /* transform: translateY(-55vh); */
  transition: opacity 300ms ease-in-out;
  will-change: opacity;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewport-password-dialog.entering > .inner { opacity: 0; }
.viewport-password-dialog.entered > .inner { opacity: 1; }
.viewport-password-dialog.exiting > .inner { opacity: 0; }
.viewport-password-dialog.exited > .inner { opacity: 0; }

.viewport-password-dialog > .inner input {
  /* border: 1px solid rgba(0,0,0,0.5); */
}

.viewport-password-dialog .form-control.is-invalid {
  border-color: inherit;
}

.viewport-password-dialog > .inner input {
  /* border: 1px solid rgba(0,0,0,0.5); */
}
