.viewport-alert {
  background: rgba(255,255,255,0.25);
  border: 1px solid rgba(255,255,255,0.55);
  font-size: 1.4vmin;
  border-radius: 1vmin;
  padding: 1vmin;
  color: rgba(255,255,255,0.85);
  display: flex;
  align-items: center;
}

.viewport-alert > p {
  margin: 0 0 0 1vmin;
}
