.program-preview-transition-state-controller {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  z-index: 10000;
}

.program-preview-transition-state-controller button {
  visibility: hidden;
}

.program-preview-transition-state-controller:hover button {
  visibility: visible;
}
