@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,800");

.viewport-scene {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
	font-family: 'Montserrat', sans-serif;
	background-color: #131f3a;
	color: white;
}

