.viewport-program-gallery-image {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: opacity 500ms ease-in-out;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.viewport-program-gallery-image header {
  text-align: center;
  background: rgba(0,0,0,0.5);
  display: block;
  height: auto;
  color: rgba(255,255,255,0.85);
  padding: 1.3vw 2vw 0.9vw 2vw;
  border-bottom-left-radius: 2vmin;
  border-bottom-right-radius: 2vmin;
}

.viewport-program-gallery-image.entering,
.viewport-program-gallery-image.exiting,
.viewport-program-gallery-image.exited {
  opacity: 0;
}
.viewport-program-gallery-image.entered {
  opacity: 1;
}
