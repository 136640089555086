.channel-card .channel-card-delete-btn {
  display: none;
  position: absolute;
  top: 4px;
  right: 5px;
}

.channel-card:hover .channel-card-delete-btn {
  display: block;
}

