.spinner-overlay-container {
  position: relative;
}

.spinner-overlay-container .spinner-children {
  transition: opacity 0.25s ease-in-out;
}

.spinner-overlay-container.active .spinner-children {
  background: #fff;
  opacity: 0.35;
}

.spinner-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
}

.spinner-container.center-x {
  justify-content: center;
}

.spinner-container.center-y {
  align-items: center;
}
