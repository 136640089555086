.flex-page {
  display: flex;
  justify-content: space-between;
}

/**
 * Reverts some styles automatically applied in reactstrap and not compatible with
 * the slim theme
 */
#root .alert-dismissible .close {
  position: relative;
  top: auto;
  right: auto;
  padding: 0;
  color: #000;
}
