.viewport-websocket-state {
  position: absolute;
  right: 2vmin;
  bottom: 2vmin;
  width: 50vmin;
  z-index: 100;
  will-change: opacity;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.viewport-websocket-state-enter-done {
  opacity: 1;
}
